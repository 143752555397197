import { IDinners, IMeal, IVote, IWeeksDinners, LineItem } from "../types"

interface IFetchMealsApiResponse {
    meal: IMeal[]
}

interface IFetchMealsResponse {
    success: boolean
    errorMessage?: string
    meals?: IMeal[]
}



export const fetchMeals = async (): Promise<IFetchMealsResponse> => {
    const outcome: IFetchMealsResponse = {
        success: false
    }

    try {
        const url = 'https://30pheucg96.execute-api.ap-southeast-1.amazonaws.com/example/meal'
        const response = await fetch(url, {
            mode: 'cors',
            headers: {
                'Authorization': `${sessionStorage.getItem("idToken") || ''}`
            },
            //credentials: "include"
        })
        if (!response.ok) {
            if (response.status === 401) {
                sessionStorage.removeItem("idToken")
                sessionStorage.removeItem("accessToken")
                sessionStorage.removeItem("refreshToken")
                sessionStorage.removeItem("username")
            }
            outcome.errorMessage = `An error occurred, status code ${response.status}`
            return outcome
        }

        const responseJson: IFetchMealsApiResponse = await response.json();
        outcome.meals = responseJson.meal

        if (!outcome.meals) {
            outcome.errorMessage = `A problem occurred converting the response to json`
            return outcome
        }
        outcome.success = true

    } catch (error) {
        outcome.errorMessage = `An error occurred: ${error}`
    }

    return outcome
}

interface IFetchDinnersForWeekApiResponse {
    weekStartDate: string
    dinner: IDinners
}

interface IFetchDinnersForWeekResponse {
    success: boolean
    errorMessage?: string
    weeksDinners?: IWeeksDinners
}

export const fetchDinnersForWeek = async (weekStartDate: string): Promise<IFetchDinnersForWeekResponse> => {
    const outcome: IFetchDinnersForWeekResponse = {
        success: false
    }

    try {
        const url = 'https://30pheucg96.execute-api.ap-southeast-1.amazonaws.com/example/dinner?' +
            new URLSearchParams({weekStartDate: weekStartDate}).toString()
        
        console.log("dandebug - fetching dinners for week: " + weekStartDate)

        const response = await fetch(url,  {
            mode: 'cors',
            headers: {
                'Authorization': `${sessionStorage.getItem("idToken") || ''}`
            },
        })  
        if (!response.ok) {
            if (response.status === 401) {
                sessionStorage.removeItem("idToken")
                sessionStorage.removeItem("accessToken")
                sessionStorage.removeItem("refreshToken")
                sessionStorage.removeItem("username")
            }
            outcome.errorMessage = `An error occurred, status code ${response.status}`
            return outcome
        }

        console.log("dandebug - pulling out json response: " + weekStartDate)

        const resposeJson: IFetchDinnersForWeekApiResponse = await response.json();
        outcome.weeksDinners = {
            dinners: resposeJson.dinner,
            weekStartDate: resposeJson.weekStartDate
        }
        outcome.success = true
    } catch (error) {
        outcome.errorMessage = `An error occurred: ${error}`
    }

    return outcome
}

interface IPostDinnerVotesResponse {
    success: boolean
    errorMessage?: string
}

export const postDinnerVotes = async (votes: IVote): Promise<IPostDinnerVotesResponse> => {
    const outcome: IPostDinnerVotesResponse = {
        success: false
    }

    try {
        const url = 'https://30pheucg96.execute-api.ap-southeast-1.amazonaws.com/example/dinner'

        const response = await fetch(url, {
            mode: 'cors', 
            method: "POST",
            body: JSON.stringify(votes),
            headers: {
                'Authorization': `${sessionStorage.getItem("idToken") || ''}`
            },
        })

        if (!response.ok) {
            if (response.status === 401) {
                sessionStorage.removeItem("idToken")
                sessionStorage.removeItem("accessToken")
                sessionStorage.removeItem("refreshToken")
                sessionStorage.removeItem("username")
            }
            outcome.errorMessage = `An error occurred, status code ${response.status}`
            return outcome
        }

        outcome.success = true

    } catch (error) {
        outcome.errorMessage = `An error occurred: ${error}`
    }

    return outcome
}

interface IFetchVoteApiResponse {
    vote?: IVote
}

interface IFetchVoteResponse {
    success: boolean
    errorMessage?: string
    vote?: IVote
}

export const fetchVote = async (weekStartDate: string, user: string): Promise<IFetchVoteResponse> => {
    const outcome: IFetchVoteResponse = {
        success: false
    }

    try {
        const url = 'https://30pheucg96.execute-api.ap-southeast-1.amazonaws.com/example/vote?' +
            new URLSearchParams({
                weekStartDate,
                user
            }).toString()

            const response = await fetch(url, {
                mode: 'cors',
                headers: {
                    'Authorization': `${sessionStorage.getItem("idToken") || ''}`
                }
            })
    
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.removeItem("idToken")
                    sessionStorage.removeItem("accessToken")
                    sessionStorage.removeItem("refreshToken")
                    sessionStorage.removeItem("username")
                }
                outcome.errorMessage = `An error occurred, status code ${response.status}`
                return outcome
            }

            const resposeJson: IFetchVoteApiResponse = await response.json();
            outcome.vote = resposeJson.vote
            outcome.success = true
    } catch (error) {
        outcome.errorMessage = `An error occurred: ${error}`
    }

    return outcome
}

interface IFetchPockeyMoneyBalance {
    success: boolean
    errorMessage?: string
    user: string
    amount: number
}

export const fetchPockeyMoneyBalance = async (): Promise<IFetchPockeyMoneyBalance> => {
    const outcome: IFetchPockeyMoneyBalance = {
        success: false,
        user: "",
        amount: 0
    }

    try {
        const url = 'https://30pheucg96.execute-api.ap-southeast-1.amazonaws.com/example/balance'

            const response = await fetch(url, {
                mode: 'cors',
                headers: {
                    'Authorization': `${sessionStorage.getItem("idToken") || ''}`
                }
            })
    
            // 404 is ok if we didnt find the vote
            if (!response.ok && response.status !== 404) {
                if (response.status === 401) {
                    sessionStorage.removeItem("idToken")
                    sessionStorage.removeItem("accessToken")
                    sessionStorage.removeItem("refreshToken")
                    sessionStorage.removeItem("username")
                }
                outcome.errorMessage = `An error occurred, status code ${response.status}`
                return outcome
            }

            const resposeJson: IFetchPockeyMoneyBalance = await response.json();
            outcome.user = resposeJson.user
            outcome.amount = resposeJson.amount
            outcome.success = true
    } catch (error) {
        outcome.errorMessage = `An error occurred: ${error}`
    }

    return outcome
}

interface IFetchPockeyMoneyTransactions {
    success: boolean
    errorMessage?: string
    user: string
    lineEntries: LineItem[]
}


export const fetchPockeyMoneyTransactions = async (): Promise<IFetchPockeyMoneyTransactions> => {
    const outcome: IFetchPockeyMoneyTransactions = {
        success: false,
        user: "",
        lineEntries: []
    }

    try {
        const url = 'https://30pheucg96.execute-api.ap-southeast-1.amazonaws.com/example/transactions'

            const response = await fetch(url, {
                mode: 'cors',
                headers: {
                    'Authorization': `${sessionStorage.getItem("idToken") || ''}`
                }
            })
    
            // 404 is ok if we didnt find the vote
            if (!response.ok && response.status !== 404) {
                if (response.status === 401) {
                    sessionStorage.removeItem("idToken")
                    sessionStorage.removeItem("accessToken")
                    sessionStorage.removeItem("refreshToken")
                    sessionStorage.removeItem("username")
                }
                outcome.errorMessage = `An error occurred, status code ${response.status}`
                return outcome
            }

            const resposeJson: IFetchPockeyMoneyTransactions = await response.json();
            outcome.user = resposeJson.user
            outcome.lineEntries = resposeJson.lineEntries.map(l => {
                return {
                    amount: l.amount,
                    dateTime:  new Date(l.dateTime),
                    description: l.description,
                    user: l.user
                }
            })
            console.log("dandebug - responseJson " + JSON.stringify(resposeJson))
            console.log("dandebug - responseJson.lineitems " + resposeJson.lineEntries)
            console.log("dandebug - outcome.lineitems " + outcome.lineEntries)
            outcome.success = true
    } catch (error) {
        outcome.errorMessage = `An error occurred: ${error}`
    }

    return outcome
}