import React, { useEffect, useState } from "react";
import { IMeal, IVote } from "./types";
import { fetchMeals, fetchVote, postDinnerVotes } from "./api/api";
import { convertDateFormatForApi, firstDayOfNextWeek, firstDayOfThisWeek } from "./util";
import DinnerVoteSelect from "./DinnerVoteSelect";

interface DinnerVoteProps {

}

const DinnerVote = (props: DinnerVoteProps) => {

    const [errorMessage, setErrorMessage] = useState<string>('')
    const [meals, setMeals] = useState<IMeal[]>()
    const [votes, setVotes] = useState<IMeal[]>(new Array(3))
    const [voted, setVoted] = useState<boolean>(false)
    const [isSubmittingVotes, setIsSubmittingVotes] = useState<boolean>(false)

    useEffect(() => {
        async function fetchMealsData() {
            const fetchMealsResponse = await fetchMeals();
            if (fetchMealsResponse.errorMessage) {
              setErrorMessage(fetchMealsResponse.errorMessage)
            }
            if (fetchMealsResponse.meals) {
              setMeals(fetchMealsResponse.meals)
            }
        }

        async function fetchCurrentVotes() {
            console.log("dandebug - fetching current votes")
            const mondayDate = firstDayOfNextWeek(new Date(), 1)
            const dateString = convertDateFormatForApi(mondayDate)
            const username = sessionStorage.getItem("username") || 'notfound'
            const fetchVoteResponse = await fetchVote(dateString, username)

            if (fetchVoteResponse.errorMessage) {
                setErrorMessage(fetchVoteResponse.errorMessage)
            }
            if (fetchVoteResponse.vote) {
                // set current votes
                setVotes(fetchVoteResponse.vote.meals)
                setVoted(true)
                console.log(fetchVoteResponse.vote)
            }
        }

        fetchMealsData()
        fetchCurrentVotes()
    }, [])

    const submitVotes = async () => {
        console.log("dandebug - called submitVotes")

        if (votes.some(m => m === undefined)) {
            setErrorMessage("Must select all votes")
        }

        const username = sessionStorage.getItem("username")

        if (username === null ) {
            setErrorMessage("Problem retrieving your username, please log out and back in")
            return
        }

        const newVotes: IVote = {
            weekStartDate: convertDateFormatForApi(firstDayOfNextWeek(new Date(), 1)),
            meals: votes.map(m => m),
            user: username
        }

        const response = await postDinnerVotes(newVotes)
        if (!response.success) {
            if (response.errorMessage) {
                setErrorMessage(response.errorMessage)
            } else {
                setErrorMessage("There was an error submitting your votes")
            }
        }
        setVoted(true)
    }

    return <section className="py-14">
    <div className="container mx-auto max-w-[325px]">
        <div className="flex flex-wrap "></div>
            <p className="text-center">Dinner Vote</p>

                <DinnerVoteSelect 
                    voteIndex={0}
                    votes={votes} 
                    meals={meals} 
                    setVotes={setVotes} 
                />
                <DinnerVoteSelect 
                    voteIndex={1}
                    votes={votes} 
                    meals={meals} 
                    setVotes={setVotes} 
                />
                <DinnerVoteSelect 
                    voteIndex={2}
                    votes={votes} 
                    meals={meals} 
                    setVotes={setVotes} 
                />

                    <button type="button" 
                        onClick={() => submitVotes()}
                        className="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800" >
                        {voted ? 'Change votes' : 'Submit Votes'}
                    </button>
                    <p className="text-red" >{errorMessage}</p>


        </div>
        </section>

}

export default DinnerVote;
